import { notification } from 'antd';
import { Paragraph, Title } from 'common-components/typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import ChangeDefaultSuccessModal from './components/FinanceExportConfigurationModals/ChangeDefaultSuccessModal';
import ConfigCard from './components/ConfigCard';
import ConfirmChangeDefaultModal from './components/FinanceExportConfigurationModals/ConfirmChangeDefaultModal';
import SelectDefaultFileExportModal from './components/FinanceExportConfigurationModals/SelectDefaultFileExportModal';
import { ExportFileConfigType } from './utils/account-management-constants';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';

const FinanceFileExportConfigurationView = () => {
  const dispatch = useDispatch<IRootDispatch>();

  const { allowPayCodeConfig, pinc1102PayPeriodConfiguration } = useFlags();

  const { defaultExportFileState, financeStyleState, timeSheetStyleState } = useSelector((state: IRootState) => ({
    defaultExportFileState: state.accountStore.defaultExportFileState,
    financeStyleState: state.accountStore.financeStyleState,
    timeSheetStyleState: state.accountStore.timeSheetStyleState,
  }));

  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedStyleKey, setSelectedStyleKey] = useState<string>('');
  const [configType, setConfigType] = useState<ExportFileConfigType>(undefined);
  const [isSelectModalVisible, setIsSelectModalVisible] = useState(false);
  const [isConfirmChangeModalVisible, setIsConfirmChangeModalVisible] = useState(false);
  const [isSuccessChangedModalVisible, setIsSuccessChangedModalVisible] = useState(false);
  const portalUser = usePortalUser();

  const useDefaultStyle =
    configType === ExportFileConfigType.ACCOUNT_SYSTEM
      ? financeStyleState.useDefaultStyle
      : timeSheetStyleState.useDefaultStyle;

  const isChangedStyleKey =
    configType === ExportFileConfigType.ACCOUNT_SYSTEM
      ? selectedStyleKey !== defaultExportFileState.accountSystem.key
      : selectedStyleKey !== defaultExportFileState.payrollSystem.key;

  const updateConfigDefaultByType = async (payload: { styleKey: string }) => {
    if (configType === ExportFileConfigType.ACCOUNT_SYSTEM) {
      await dispatch.accountStore.doUpdateDefaultAccountingSystem(payload);
    } else {
      await dispatch.accountStore.doUpdateDefaultPayrollSystem(payload);
      dispatch.authStore.setCurrentPortalUser({
        ...portalUser,
        integrationConfig: {
          ...portalUser.integrationConfig,
          timesheetExport: { style: payload.styleKey },
        },
      });
    }
  };

  const onSaveAsDefault = async () => {
    setIsSelectModalVisible(false);
    if (isChangedStyleKey) {
      return setIsConfirmChangeModalVisible(true);
    }
    if (!useDefaultStyle) {
      await updateConfigDefaultByType({ styleKey: selectedStyleKey });
      setIsSuccessChangedModalVisible(true);
    }
  };

  const showSelectOptionModal = (configType: ExportFileConfigType) => {
    setSelectedStyleKey(
      defaultExportFileState[configType === ExportFileConfigType.ACCOUNT_SYSTEM ? 'accountSystem' : 'payrollSystem']
        .key,
    );
    setIsSelectModalVisible(true);
  };

  const onSelectConfigCard = async (configType: ExportFileConfigType) => {
    try {
      setConfigType(configType);
      showSelectOptionModal(configType);
    } catch (error) {
      notification.error({ message: error?.message || 'Oops, something went wrong! Please try again.' });
    }
  };

  const onConfirmChange = async () => {
    const payload = { styleKey: selectedStyleKey };
    try {
      setIsUpdating(true);
      await updateConfigDefaultByType(payload);
      setIsSuccessChangedModalVisible(true);
    } catch (error) {
      notification.error({ message: error?.message || 'Oops, something went wrong! Please try again.' });
    } finally {
      setIsUpdating(false);
      setIsConfirmChangeModalVisible(false);
    }
  };

  const getDefaultConfigs = async () => {
    await Promise.all([
      dispatch.accountStore.doGetFinanceExportDefaultConfigs(),
      dispatch.accountStore.doGetAccountingSystemList(),
      dispatch.accountStore.doGetPayrollSystemList(),
    ]);
  };

  useEffect(() => {
    getDefaultConfigs();
  }, []);

  return (
    <>
      <>
        <div className='pb-x4-large'>
          <div className='mb-medium'>
            <Title level={3} weight='regular' className='mv-none'>
              <Link to='/account/landing'>
                <span className='text-color-blue-action cursor-pointer'>Account</span>
              </Link>
              <span className='text-color-secondary mr-x-small'>{' > '}</span>
              <Link to='/account/features'>
                <span className='text-color-blue-action cursor-pointer'>Feature Configuration</span>
              </Link>
              <span className='text-color-secondary mr-x-small'>{' > '}</span>
              Finance file exports
            </Title>
          </div>
          <Paragraph>
            Select from leading accounting and payroll systems to configure your exports for easy reconcillation.
          </Paragraph>
          <div className='mt-x-large bordered-bottom border-standard-gray'>
            <ConfigCard
              title='Accounting system'
              description='Configure your finance exports by selecting your accounting system.'
              actionLabelText={defaultExportFileState['accountSystem'].name}
              actionLabelColor='blue-action'
              isActionLabelLoading={!defaultExportFileState['accountSystem']?.name}
              onClick={() => onSelectConfigCard(ExportFileConfigType.ACCOUNT_SYSTEM)}
            />

            <ConfigCard
              title='Payroll system'
              description='Configure your timesheet exports by selecting your payroll system.'
              actionLabelText={defaultExportFileState['payrollSystem'].name}
              actionLabelColor='blue-action'
              isActionLabelLoading={!defaultExportFileState['payrollSystem']?.name}
              onClick={() => onSelectConfigCard(ExportFileConfigType.PAYROLL_SYSTEM)}
            />

            {allowPayCodeConfig && (
              <ConfigCard
                title='Work and leave types'
                description='Configure your work and leave types and set external IDs.'
                actionLabelText='Configure'
                href='/account/features/finance-file-export-configuration/work-types-leave-types'
              />
            )}
            {pinc1102PayPeriodConfiguration && (
              <ConfigCard
                title='Pay period'
                description='Configure your pay period'
                actionLabelText='Configure'
                href='/account/features/finance-file-export-configuration/pay-period'
              />
            )}
          </div>
        </div>
      </>

      <SelectDefaultFileExportModal
        isOpen={isSelectModalVisible}
        styleOptions={
          configType === ExportFileConfigType.ACCOUNT_SYSTEM
            ? financeStyleState.configTypes
            : timeSheetStyleState.configTypes
        }
        type={configType}
        selectedStyle={selectedStyleKey}
        onSave={onSaveAsDefault}
        onSelect={setSelectedStyleKey}
        onClose={() => setIsSelectModalVisible(false)}
      />

      <ConfirmChangeDefaultModal
        type={configType}
        isUpdating={isUpdating}
        isOpen={isConfirmChangeModalVisible}
        onClose={() => setIsConfirmChangeModalVisible(false)}
        onConfirm={onConfirmChange}
      />

      <ChangeDefaultSuccessModal
        isSetDefaultFirstTime={!useDefaultStyle}
        isConfigPage
        isChangedStyleKey={isChangedStyleKey}
        type={configType}
        isOpen={isSuccessChangedModalVisible}
        onClose={() => {
          setIsSuccessChangedModalVisible(false);
          getDefaultConfigs();
        }}
      />
    </>
  );
};

export default FinanceFileExportConfigurationView;
