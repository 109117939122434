import { DateValue, ZonedDateTime } from '@internationalized/date';
import { differenceInHours, differenceInMinutes, differenceInMonths, differenceInYears, parseISO } from 'date-fns';
import moment, { Moment } from 'moment';
import { z } from 'zod';

export type AnyDateType = Date | string | Moment;

export const formatWeeksFrom = (date: Moment): string => {
  const daysFromDate = Math.abs(moment().diff(date, 'days'));
  const asWeeks = moment.duration(daysFromDate, 'days').asWeeks();
  const [weeks, days] = asWeeks.toString().split(/[.]/);

  if (!days && weeks === '0') {
    return `${daysFromDate}d`;
  } else if (weeks === '0') {
    return `${daysFromDate}d`;
  } else if (!days) {
    return `${weeks}w`;
  } else {
    return `${weeks}w ${days[0]}d`;
  }
};

export function convertToDate(date: DateValue | unknown, timezone: string): Date;
export function convertToDate(date: AnyDateType | ZonedDateTime, timezone?: never): Date;
export function convertToDate(date: AnyDateType | DateValue | unknown, timezone?: string | never): Date {
  if (date instanceof Date) {
    return date;
  }

  if (typeof date === 'string') {
    return new Date(date);
  }

  if (moment.isMoment(date)) {
    return date.toDate();
  }

  if (isDateValue(date)) {
    if (isZonedDateTime(date)) {
      return date.toDate();
    }

    if (!timezone) {
      throw new Error('Timezone must be provided when converting DateValue to Date');
    }

    return date.toDate(timezone);
  }

  throw new Error('Invalid date type when converting to date');
}

export const convertToMoment = (date: AnyDateType): Moment => {
  if (date instanceof Date) {
    return moment(date);
  }

  if (date instanceof moment) {
    return date;
  }

  if (typeof date === 'string') {
    return moment(date);
  }

  throw new Error('Invalid date type when converting to moment');
};

export const timeBetween = (start: AnyDateType, end: AnyDateType, unitOfTime: moment.unitOfTime.Diff): number => {
  const startMoment = convertToMoment(start);
  const endMoment = convertToMoment(end);
  return Math.abs(endMoment.diff(startMoment, unitOfTime, true));
};

export const minutesBetween = (start: AnyDateType, end: AnyDateType) => timeBetween(start, end, 'minutes');
export const hoursBetween = (start: AnyDateType, end: AnyDateType) => timeBetween(start, end, 'hours');
export const daysBetween = (start: AnyDateType, end: AnyDateType) => timeBetween(start, end, 'days');

export const differenceInDaysNotRounded = (dateLeft: Date, dateRight: Date) =>
  differenceInHours(dateLeft, dateRight) / 24;
export const differenceInHoursNotRounded = (dateLeft: Date, dateRight: Date) =>
  differenceInMinutes(dateLeft, dateRight) / 60;

export const isDateValue = (dateValue: unknown): dateValue is DateValue => {
  if (typeof dateValue !== 'object' || dateValue === null) {
    return false;
  }

  return Boolean(
    'day' in dateValue && 'month' in dateValue && 'year' in dateValue && 'calendar' in dateValue && 'era' in dateValue,
  );
};

export const isZonedDateTime = (dateValue: DateValue): dateValue is ZonedDateTime => {
  return Boolean('timeZone' in dateValue);
};

export const ZodDateValue = z
  .custom<DateValue>((date) => isDateValue(date), { message: 'Date is invalid' })
  .refine((date) => date.year > 1900 && date.year < 2100, { message: 'Year is invalid' });

const getPluralForm = (singular: string, count: number, plural?: string): string => {
  if (count === 1) {
    return singular;
  }
  return plural || `${singular}s`;
};

export const ageDisplay = (dateOfBirth: string | undefined): string => {
  if (!dateOfBirth) return 'DOB not set';

  const dob = parseISO(dateOfBirth);
  const currentDate = new Date();

  const years = differenceInYears(currentDate, dob);
  const months = differenceInMonths(currentDate, dob) - years * 12;

  if (years < 21) {
    return `${years} ${getPluralForm('year', years)}, ${months} ${getPluralForm('month', months)}`;
  }

  return `${years} ${getPluralForm('year', years)}`;
};
