import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';

import styles from '../styles/customer-details.module.css';

import { IServiceListItemLite } from 'interfaces/service-interfaces';
import { ICustomer } from 'interfaces/customer-interfaces';

import { FieldLabel, FieldValueText, SubTitle, Text } from 'common-components/typography';
import { Title } from '@good/ui/core';
import { Avatar, Col, Icon, notification, Row, Skeleton, Tabs, Tag } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';

import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment-timezone';
import { tagColors } from 'theme/theme-variables';

import CustomerDetailsFundingPanel from './tabs-panel/funding/CustomerDetailsFundingPanel';
import CustomerDetailsContactsPanel from './tabs-panel/contacts/CustomerDetailsContactsPanel/contact-list';
import CustomerDetailsGeneralPanel from './tabs-panel/general/CustomerDetailsGeneralPanel';

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CustomerTag from '../../../common-components/tags/CustomerTag';
import ViewConnectionRequestsModal from 'views/customers/details/components/ViewConnectionRequestsModal';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import AddCustomerConnectionModal from './components/AddCustomerConnectionModal';
import CareInformationPanel from './tabs-panel/care-information';
import CustomerFiles from './tabs-panel/files/CustomerFiles';
import CustomerDetailsBookingPanel from 'views/customers/details/tabs-panel/CustomerDetailsBookingPanel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import PermissionUtils from 'utilities/permission-utils';
import CustomerDetailsNotesPanel from 'views/customers/details/tabs-panel/CustomerDetailsNotesPanel';
import NewServiceAgreementsPanel from 'views/customers/details/tabs-panel/new-service-agreements/NewServiceAgreementsPanel';
import ManageCustomerActionModel from 'views/customers/details/components/ManageCustomerActionModel';
import { CustomerStatusType, PlanManagerType, CustomerDetailsTabKey, UserPermissionRole } from 'utilities/enum-utils';
import CarerPreferencesPanel from 'views/customers/details/tabs-panel/carer-preferences/CarerPreferencesPanel';
import PinnedAlertItem from 'common-components/pinned-alerts/PinnedAlertItem';
import { Error } from 'common-components/alerts';
import CustomerDetailsLinkedAccountsPanel from './tabs-panel/linkaccounts-app/CustomerDetailsLinkedAccountsPanel';
import GuardianManagedAccountsPanel from './tabs-panel/managed-accounts/GuardianManagedAccountsPanel';
import CustomerFormsPanel from './tabs-panel/forms/CustomerFormsPanel';
import WorkflowsPanel from 'common-components/workflow/WorkflowsPanel';
import CreateNewActivityRecordModalV2 from 'views/bookings/listings/components/CreateNewActivityRecordModalV2';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { Popover2 } from '@blueprintjs/popover2';
import BudgetingPanel from './tabs-panel/budgeting/BudgetingPanel';
import { Flag } from 'common-components/utils/launchdarkly/flag';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ViewProfilePictureModal from './profile-photo/components/ViewProfilePictureModal';
import FundingPackageInfo from './components/FundingPackageInfo';
import { ServiceAgreementsPanel } from './tabs-panel/service-agreements-panel/ServiceAgreementsPanel';
import { Medias } from 'design-components/medias';
import { Camera } from '@good/icons';
import { Button } from '@good/components';
import { ageDisplay } from 'utilities/date-utils';

const { TabPane } = Tabs;

type ICustomerUrlParams = {
  userId: string;
};

type ICustomerDetailsPanelProps = {
  crem61?: boolean;
  toggleSidebar: () => void;
  showSidebar: boolean;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  selectedCustomerUserId: string;
  selectedCustomer: ICustomer;
  doSubmitCustomerSetting: typeof dispatch.customersStore.doSubmitCustomerSetting;
  servicesLite: IServiceListItemLite[];
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  doCreateUserServiceAgreement: typeof dispatch.customersStore.doCreateUserServiceAgreement;
  doUpdateUserServiceAgreement: typeof dispatch.customersStore.doUpdateUserServiceAgreement;
  doUpdateCustomerNDISInfo: typeof dispatch.customersStore.doUpdateCustomerNDISInfo;
  updateCustomerProfile: typeof dispatch.customersStore.updateCustomerProfile;
  setSelectedCustomerUserId?: typeof dispatch.customersStore.setSelectedCustomerUserId;
  portalUser: typeof state.authStore.portalUser;
  customerDocuments: typeof state.customersStore.customerDocuments;
  doFetchCustomerDocuments: typeof dispatch.customersStore.doFetchCustomerDocuments;
  doFetchMoreDocuments: typeof dispatch.customersStore.doFetchMoreCustomerDocuments;
  setCustomerDocuments: typeof dispatch.customersStore.setCustomerDocuments;
  doAcceptEnquiry: typeof dispatch.customersStore.doAcceptEnquiry;
  resetUserServiceAgreementBillingLineItems: typeof dispatch.customersStore.resetUserServiceAgreementBillingLineItems;
  doAddCustomerNotes: typeof dispatch.customersStore.doAddCustomerNotes;
  doEditCustomerNote: typeof dispatch.customersStore.doEditCustomerNote;
  doFetchBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyDataLite: typeof state.companyStore.companyDataLite;
  workflowListOnProfile: typeof state.workflowStore.workflowListOnProfile;
  doFetchWorkflowListOnProfile: typeof dispatch.workflowStore.doFetchWorkflowListOnProfile;
  setIsCreatePackageModalOpen: typeof dispatch.customersStore.setIsCreatePackageModalOpen;
  editPermissionsData: typeof state.accountStore.editPermissionsData;
} & FormComponentProps &
  RouteComponentProps<ICustomerUrlParams, Record<string, unknown>, { selectedTab: any }>;

type ICustomerDetailsPanelState = {
  isLoading: boolean;
  selectedTab: any;
  isAgreementEdit: boolean;
  isOpenConnectUser: boolean;
  openSendConnectionModel: boolean;
  isAcceptEnquiryOpen: boolean;
  isAcceptingEnquiry: boolean;
  isCustomerManageOpen: boolean;
  showCreateActivityRecordModal: boolean;
  isOpenViewProfilePictureModal: boolean;
  isProfilePictureLoadError: boolean;
};

class CustomerDetailsPanel extends Component<
  ICustomerDetailsPanelProps & { flags: { [key: string]: boolean } },
  ICustomerDetailsPanelState
> {
  state = {
    selectedTab:
      this.props.location && this.props.location.state && this.props.location.state.selectedTab
        ? this.props.location.state.selectedTab
        : 'generalinfo',

    isLoading: false,
    isAgreementEdit: false,
    isOpenConnectUser: false,
    openSendConnectionModel: false,
    isAcceptEnquiryOpen: false,
    isAcceptingEnquiry: false,
    isCustomerManageOpen: false,
    showCreateActivityRecordModal: false,
    isOpenViewProfilePictureModal: false,
    isProfilePictureLoadError: false,
  };

  private _addFundingPackage = () => {
    this.setState({ selectedTab: 'fundings' });
    this.props.setIsCreatePackageModalOpen(true);
  };

  private _setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  private _renderDisability = () => {
    const { selectedCustomer } = this.props;

    return (
      <div>
        {selectedCustomer.disabilities.map((item) => {
          return (
            <Tag color={tagColors.BLUE} className={'mb-small'} key={item && item.tagId}>
              <Text color='white' weight='bold' size='large'>
                {item ? item.disabilityName : 'Not found'}
              </Text>
            </Tag>
          );
        })}
      </div>
    );
  };

  private _renderParentGuardianBadge = () => {
    const { selectedCustomer } = this.props;
    const { isDependent } = selectedCustomer;
    return isDependent && <CustomerTag type='HAS_GUARDIANS' className={'mr-small'} />;
  };

  private _renderConnectionBadge = (hasEditPermission) => {
    const { selectedCustomer } = this.props;
    const { connected } = selectedCustomer;
    return (
      <CustomerTag
        type={connected}
        // showConnectButton={
        //   hasEditPermission &&
        //   (selectedCustomer.isDependent || selectedCustomer.isIndependent) &&
        //   selectedCustomer.customerStatus !== CustomerStatusType.ARCHIVED
        // }
        // onClickButton={() => this._openConnectionManager(connected)}
      />
    );
  };

  private _onRefresh = async () => {
    const { doGetCustomer, match } = this.props;
    const { params } = match;
    const selectedCustomerId = params.userId;
    await doGetCustomer({ userId: selectedCustomerId });
  };

  async componentDidMount() {
    const {
      doGetCustomer,
      match,
      setSelectedCustomerUserId,
      resetUserServiceAgreementBillingLineItems,
      doFetchCompanyLite,
    } = this.props;
    const { params } = match;
    const selectedCustomerId = params.userId;

    setSelectedCustomerUserId(selectedCustomerId);

    this.setState({ isLoading: true });

    // fetch customer
    await doGetCustomer({ userId: selectedCustomerId, skipBookings: true });

    await doFetchCompanyLite();

    resetUserServiceAgreementBillingLineItems({});

    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps, prevState, snapshot?) {
    const { match, doGetCustomer, resetUserServiceAgreementBillingLineItems } = this.props;
    const previousMatch = prevProps.match;
    if (previousMatch) {
      const { params } = match;
      const previousParams = previousMatch.params;
      const selectedCustomerId = params.userId;
      const previousSelectedId = previousParams.userId;

      /**
       * This will check if there is an existing selected tab from the history.
       * (ie: when user select a notification it will be redirected to the set selectedTab or when user click on alerts view details.)
       */
      if (
        this.state.selectedTab === prevState.selectedTab &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.selectedTab &&
        this.props.location.state.selectedTab !== this.state.selectedTab
      ) {
        const state = cloneDeep(this.props.location.state);

        this.setState({ selectedTab: state.selectedTab });

        delete state.selectedTab;
        history.replaceState(state, '');
      }

      if (selectedCustomerId !== previousSelectedId) {
        this.setState({ isLoading: true });

        await doGetCustomer({ userId: selectedCustomerId });

        resetUserServiceAgreementBillingLineItems({});

        this.setState({ isLoading: false, selectedTab: 'generalinfo' });
      }
    }
  }

  private _onCloseConnectUser = () => {
    this.setState({ isOpenConnectUser: false });
  };

  private _onCloseAcceptEnquiryModal = () => {
    this.setState({ isAcceptEnquiryOpen: false });
  };

  private _onOpenCustomerManageModal = () => {
    this.setState({ isCustomerManageOpen: true });
  };

  private _onCloseCustomerManageModal = () => {
    this.setState({ isCustomerManageOpen: false });
  };

  private _onOpenCreateActivityRecord = () => {
    this.setState({ showCreateActivityRecordModal: true });
  };

  private _onCloseCreateActivityRecord = () => {
    this.setState({ showCreateActivityRecordModal: false });
  };

  private _acceptEnquiry = async () => {
    try {
      this.setState({ isAcceptingEnquiry: true });
      await this.props.doAcceptEnquiry({ customerUserId: this.props.selectedCustomer.userId });
      notification.success({ message: 'Enquiry successfully accepted!' });
      this._onCloseAcceptEnquiryModal();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isAcceptingEnquiry: false });
  };

  private _onOpenViewProfilePictureModal = () => {
    this.setState({ isOpenViewProfilePictureModal: true });
  };

  private _onCloseViewProfilePictureModal = () => {
    this.setState({ isOpenViewProfilePictureModal: false });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <>
          <div className='item-container'>
            <div className='pb-medium'>
              <Text>Fetching customer details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} active avatar title paragraph />
        </>
      );
    }

    const { selectedCustomer, portalUser, history, location, match, editPermissionsData } = this.props;
    const primaryCarer = !isEmpty(selectedCustomer)
      ? selectedCustomer.emergencyContacts.find((contact) => {
          return contact.isPrimaryCarer;
        })
      : undefined;

    const hasEditPermission = PermissionUtils.validatePermission(
      'EditCustomerProfile',
      portalUser.permissions.permissionRoles,
    );

    const hasPermissionToEditProfile =
      !!editPermissionsData.permissions.permissionRoles.filter(
        (item) => item.permissionRole === UserPermissionRole.CustomerAccessManager,
      ).length || hasEditPermission;

    const hasCreateActivityRecordPermission = selectedCustomer?.customerStatus !== CustomerStatusType.ARCHIVED;

    const isNotGuardian = () => {
      return selectedCustomer.isDependent || selectedCustomer.isIndependent;
    };

    const isDefaultAvatar = !selectedCustomer?.attachmentUrl || this.state.isProfilePictureLoadError;
    return (
      <>
        <ViewConnectionRequestsModal isOpen={this.state.isOpenConnectUser} onClose={this._onCloseConnectUser} />

        {!isEmpty(selectedCustomer) ? (
          <div className='mb-x7-large' id='scroll'>
            {selectedCustomer.customerStatus === CustomerStatusType.ENQUIRY && (
              <ActionModal
                isOpen={this.state.isAcceptEnquiryOpen}
                onClose={this._onCloseAcceptEnquiryModal}
                title={'Accept customer enquiry.'}
                showCloseButton={true}
              >
                {this.state.isAcceptingEnquiry ? (
                  <SpinningLoader size={100} message={'Accepting this enquiry...'} />
                ) : (
                  <>
                    <Text className={'mb-medium'}>
                      You are about to accept customer enquiry. Doing so will add this customer to your CRM and enable
                      some customer functions. Please only do so if this has been agreed upon with the customer.
                    </Text>
                    <ActionModalFooter>
                      <SecondaryButton className='mr-medium' size='large' onClick={this._onCloseAcceptEnquiryModal}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton size='large' onClick={this._acceptEnquiry}>
                        Accept Enquiry
                      </PrimaryButton>
                    </ActionModalFooter>
                  </>
                )}
              </ActionModal>
            )}
            <ManageCustomerActionModel
              isOpen={this.state.isCustomerManageOpen}
              onClose={this._onCloseCustomerManageModal}
            />

            {this.state.showCreateActivityRecordModal && (
              <CreateNewActivityRecordModalV2
                isOpen={this.state.showCreateActivityRecordModal}
                onClose={this._onCloseCreateActivityRecord}
                history={this.props.history}
                selectedCustomer={selectedCustomer}
              />
            )}
            {this.state.isOpenViewProfilePictureModal && (
              <ViewProfilePictureModal
                isOpen={this.state.isOpenViewProfilePictureModal}
                onClose={this._onCloseViewProfilePictureModal}
              />
            )}
            <Row>
              <div className='mb-medium'>
                <HyperlinkButton onClick={() => this.props.history.push('/customers')}>
                  <Icon type='left' /> Customer List
                </HyperlinkButton>
              </div>
            </Row>
            <Title order={2}>Customer Details</Title>

            <div className='mv-x-large  align-center'>
              <Row>
                <Flag
                  flag='lor1500CustomerProfileUpload'
                  on={
                    <Col
                      span={3}
                      className='text-align-right mr-large'
                      onClick={() => hasPermissionToEditProfile && this._onOpenViewProfilePictureModal()}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Medias
                        className={'position-relative align-center inline-flex justify-center'}
                        shape={'circle'}
                        icon={'user'}
                        src={selectedCustomer.attachmentUrl}
                        size={'x3large'}
                        imageProps={{
                          onError: () => {
                            this.setState({
                              isProfilePictureLoadError: true,
                            });
                            return true;
                          },
                          onLoad: () => {
                            this.setState({
                              isProfilePictureLoadError: false,
                            });
                          },
                        }}
                        onClick={() => hasPermissionToEditProfile && this._onOpenViewProfilePictureModal()}
                      />
                      {hasPermissionToEditProfile && (
                        <span
                          className={`absolute ${styles['sub-icon-profile-picture']} align-center flex justify-center overflow-hidden rounded-full`}
                        >
                          <Button
                            onClick={() => hasPermissionToEditProfile && this._onOpenViewProfilePictureModal()}
                            size='sm'
                            emphasis='quiet'
                            // borderRadius={'$full'}
                          >
                            <Camera className='text-gray-dark-2' />
                          </Button>
                        </span>
                      )}
                    </Col>
                  }
                  off={
                    <Col span={3} className='text-align-right mr-large'>
                      <Avatar
                        icon={'user'}
                        shape={'circle'}
                        // className="avatar-x3-large"
                        size={100}
                        src={selectedCustomer.attachmentUrl}
                      />
                    </Col>
                  }
                />
                <Col span={20}>
                  <div className='text-align-left pr-medium'>
                    <Row>
                      <Col>
                        <Title level={3} color='blue-action' className='align-center mb-12 flex'>
                          {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                          {hasCreateActivityRecordPermission && (
                            <Popover2
                              placement='top'
                              interactionKind='click'
                              className='ml-medium'
                              content={
                                <ActionMenu>
                                  <ActionMenuItem
                                    text='Create activity record'
                                    onClick={this._onOpenCreateActivityRecord}
                                  />
                                </ActionMenu>
                              }
                            >
                              <IconButton
                                icon='ellipsis'
                                size='default'
                                iconColor='blue-action'
                                color='white'
                                bordered={true}
                                className='bordered border-tertiary'
                              />
                            </Popover2>
                          )}
                        </Title>
                        {!selectedCustomer.isDependent && !selectedCustomer.isIndependent && (
                          <div className={'mb-x-small flex'}>
                            <Text>Parent/Guardian</Text>
                          </div>
                        )}
                        <CustomerTag
                          type={selectedCustomer.customerStatus}
                          showEnquiryButton={hasEditPermission}
                          className='mr-small mb-small'
                          onClickButton={this._onOpenCustomerManageModal}
                        />
                        {this._renderParentGuardianBadge()}
                        {!isEmpty(selectedCustomer.connected) && this._renderConnectionBadge(hasEditPermission)}
                        {selectedCustomer &&
                          selectedCustomer.customerStatus &&
                          selectedCustomer.customerStatus === CustomerStatusType.ARCHIVED && (
                            <Error
                              content={
                                <>
                                  This customer was archived on{' '}
                                  <b>
                                    {moment.tz(selectedCustomer.archivedOn, portalUser.timezone).format('DD/MM/YYYY')}
                                  </b>
                                </>
                              }
                              displayType='block'
                              className='mb-small mt-small'
                            />
                          )}
                        <PinnedAlertItem
                          customerUserId={selectedCustomer.userId}
                          pinnedAlertNumber={selectedCustomer.pinnedAlertNumber}
                        />
                      </Col>
                    </Row>
                    <Row className={'mt-medium'}>
                      {primaryCarer === undefined ? (
                        <Col span={12}>
                          <SubTitle>Contact Details</SubTitle>
                          <Row type={'flex'}>
                            <Col span={1}>
                              <Icon type={'phone'} />
                            </Col>
                            <Col span={11} className={'mr-x-small'}>
                              <FieldValueText
                                text={
                                  selectedCustomer.mobile
                                    ? parsePhoneNumberFromString(
                                        selectedCustomer.mobile,
                                        selectedCustomer.mobileCountryCode,
                                      ).format('INTERNATIONAL')
                                    : 'Not Set'
                                }
                              />
                            </Col>
                          </Row>
                          <Row type={'flex'}>
                            <Col span={1}>
                              <Icon type={'mail'} />
                            </Col>
                            <Col span={11} className={'mr-x-small'}>
                              <FieldValueText text={selectedCustomer.email ? selectedCustomer.email : 'Not Set'} />
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col span={12}>
                          <SubTitle>Primary Contact</SubTitle>
                          <Row>
                            <Col>
                              <FieldValueText
                                text={
                                  primaryCarer.fullName
                                    ? primaryCarer.fullName + ' (' + primaryCarer.relationToUser + ')'
                                    : null
                                }
                              />
                            </Col>
                          </Row>
                          <Row type={'flex'}>
                            <Col span={1} className={'mr-x-small'}>
                              <Icon type={'phone'} />
                            </Col>
                            <Col span={11}>
                              <FieldValueText text={primaryCarer.phoneNumber} />
                            </Col>
                          </Row>
                          <Row type={'flex'}>
                            <Col span={1} className={'mr-x-small'}>
                              <Icon type={'mail'} />
                            </Col>
                            <Col span={11}>
                              <FieldValueText text={primaryCarer.email ? primaryCarer.email : 'Not Set'} />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={12}>
                        <SubTitle>Funding</SubTitle>
                        {selectedCustomer.funding && selectedCustomer.funding.NDISNumber && (
                          <>
                            <Text>{selectedCustomer.funding.NDISNumber}</Text>{' '}
                            <Text color={'tertiary'}>(NDIS number)</Text>
                          </>
                        )}
                        <div>
                          <FundingPackageInfo
                            NDISFundingPackages={selectedCustomer.funding?.NDISFundingPackages}
                            hasEditPermission={hasEditPermission}
                            addFundingPackageAction={this._addFundingPackage}
                          />
                        </div>
                        <div>
                          <SubTitle> Age</SubTitle>
                          <Text>{ageDisplay(selectedCustomer.dateOfBirth)}</Text>
                        </div>
                      </Col>
                    </Row>
                    {selectedCustomer.funding.isPlanManager ? (
                      <Row className={'mt-medium'}>
                        <Col span={12}>
                          {selectedCustomer.funding.planManagerType === PlanManagerType.INTERNAL ? (
                            <>
                              <FieldLabel text={'Plan Manager'} />
                              <FieldValueText text={'Internally managed'} />
                            </>
                          ) : (
                            <>
                              {selectedCustomer.funding.isPlanManager ? <FieldLabel text={'Plan Manager'} /> : null}
                              <div>
                                {selectedCustomer.funding.isPlanManager ? (
                                  <FieldValueText text={selectedCustomer.funding.planManagerName} />
                                ) : null}
                              </div>
                              {selectedCustomer.funding.planManagerMobileNumber &&
                              selectedCustomer.funding.planManagerMobileNumber !== '' ? (
                                <Row type={'flex'}>
                                  <Col span={1} className={'mr-x-small'}>
                                    <Icon type={'phone'} />
                                  </Col>
                                  <Col span={11}>
                                    <FieldValueText
                                      text={parsePhoneNumberFromString(
                                        selectedCustomer.funding.planManagerMobileNumber,
                                        selectedCustomer.funding.planManagerMobileCountryCode,
                                      ).format('INTERNATIONAL')}
                                    />
                                  </Col>
                                </Row>
                              ) : null}
                              {selectedCustomer.funding.planManagerEmail &&
                              selectedCustomer.funding.planManagerEmail !== '' ? (
                                <Row type={'flex'}>
                                  <Col span={1} className={'mr-x-small'}>
                                    <Icon type={'mail'} />
                                  </Col>
                                  <Col span={11}>
                                    <FieldValueText text={selectedCustomer.funding.planManagerEmail} />
                                  </Col>
                                </Row>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </Row>
                    ) : null}
                    <Row className={'mt-medium'}>
                      <SubTitle>Permanent conditions</SubTitle>
                      <div>{selectedCustomer.disabilities.length > 0 ? this._renderDisability() : 'Not Set'}</div>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className={'mt-medium'}>
                <Col>
                  <Tabs
                    type='card'
                    className={'customer-tab mb-x-large'}
                    activeKey={this.state.selectedTab}
                    onChange={this._setSelectedTab}
                  >
                    <TabPane key={CustomerDetailsTabKey.GENERAL_INFO} tab='General Information'>
                      <CustomerDetailsGeneralPanel
                        history={history}
                        selectedCustomer={selectedCustomer}
                        hasEditPermission={hasEditPermission}
                        onRefresh={this._onRefresh}
                      />
                    </TabPane>
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.CARE_INFO} tab='Care Information'>
                        <CareInformationPanel
                          selectedCustomer={selectedCustomer}
                          hasEditPermission={hasEditPermission}
                        />
                      </TabPane>
                    )}
                    {!isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.MANAGED_ACCOUNTS} tab='Managed accounts'>
                        <GuardianManagedAccountsPanel selectedCustomer={selectedCustomer} history={history} />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.LINKED_ACCOUNTS_APP} tab='Linked Accounts + App'>
                        <CustomerDetailsLinkedAccountsPanel selectedCustomer={selectedCustomer} history={history} />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.CONTACTS} tab='Contacts'>
                        {!isEmpty(selectedCustomer) ? (
                          <CustomerDetailsContactsPanel
                            hasEditPermission={hasEditPermission}
                            selectedCustomer={selectedCustomer}
                          />
                        ) : null}
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.FUNDING} tab='Funding and goals'>
                        <CustomerDetailsFundingPanel
                          portalUser={portalUser}
                          selectedCustomer={selectedCustomer}
                          hasEditPermission={hasEditPermission}
                          history={history}
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.SERVICE_AGREEMENTS} tab='Service Agreement(s)'>
                        <Flag
                          flag='mag1127ServiceAgreementV2'
                          off={<NewServiceAgreementsPanel history={history} hasEditPermission={hasEditPermission} />}
                          on={<ServiceAgreementsPanel customerId={selectedCustomer.userId} />}
                        />
                      </TabPane>
                    )}
                    {this.props.flags.crem61 && (
                      <TabPane key={CustomerDetailsTabKey.BUDGETING} tab='Budgeting'>
                        <BudgetingPanel customerId={selectedCustomer.userId} />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.FILES} tab='Files'>
                        <CustomerFiles
                          customerUserId={selectedCustomer.userId}
                          itemDocuments={this.props.customerDocuments}
                          doFetchDocuments={this.props.doFetchCustomerDocuments}
                          doFetchMoreDocuments={this.props.doFetchMoreDocuments}
                          setDocuments={this.props.setCustomerDocuments}
                          hasEditPermission={hasEditPermission}
                          itemType='customer'
                          history={history}
                          portalUser={portalUser}
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.NOTES} tab='Notes'>
                        <CustomerDetailsNotesPanel history={history} />
                      </TabPane>
                    )}
                    <TabPane key={CustomerDetailsTabKey.FORMS} tab='Forms'>
                      <CustomerFormsPanel history={history} isNotGuardian={isNotGuardian()} />
                    </TabPane>
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.WORKFLOWS} tab='Workflows'>
                        <WorkflowsPanel
                          selectedItemId={selectedCustomer.userId}
                          itemWorkflows={this.props.workflowListOnProfile}
                          doFetchWorkflows={this.props.doFetchWorkflowListOnProfile}
                          history={history}
                          timezone={portalUser.timezone}
                          itemType='customer'
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key={CustomerDetailsTabKey.CARER_PREFERENCES} tab='Carer preferences'>
                        <CarerPreferencesPanel
                          selectedCustomer={selectedCustomer}
                          hasEditPermission={hasEditPermission}
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() &&
                      PermissionUtils.validatePermission(
                        'ViewBookingListing',
                        portalUser.permissions.permissionRoles,
                      ) && (
                        <TabPane key={CustomerDetailsTabKey.BOOKINGS} tab='Bookings'>
                          <CustomerDetailsBookingPanel
                            userId={selectedCustomer.userId}
                            onRefresh={this._onRefresh}
                            history={history}
                            location={location}
                            match={match}
                          />
                        </TabPane>
                      )}
                  </Tabs>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
        <FullScreenScrollableModal
          isOpen={this.state.openSendConnectionModel}
          onClose={() => this.setState({ openSendConnectionModel: false })}
          width={'full'}
          bgColor='tertiary'
          canCloseEsc={false}
          fixedHeight={false}
          noMarginBottom={true}
          hasFooter={false}
          headerTitle={<Title order={2}>Connect your record to a Customer’s own record via the GoodHuman app</Title>}
        >
          <AddCustomerConnectionModal onPressConnectLater={() => this.setState({ openSendConnectionModel: false })} />
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId,
  servicesLite: state.servicesStore.servicesLite,
  portalUser: state.authStore.portalUser,
  customerDocuments: state.customersStore.customerDocuments,
  workflowListOnProfile: state.workflowStore.workflowListOnProfile,
  editPermissionsData: state.accountStore.editPermissionsData,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doSubmitCustomerSetting: dispatch.customersStore.doSubmitCustomerSetting,
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doCreateUserServiceAgreement: dispatch.customersStore.doCreateUserServiceAgreement,
  doUpdateUserServiceAgreement: dispatch.customersStore.doUpdateUserServiceAgreement,
  doUpdateCustomerNDISInfo: dispatch.customersStore.doUpdateCustomerNDISInfo,
  updateCustomerProfile: dispatch.customersStore.updateCustomerProfile,
  doFetchCustomerDocuments: dispatch.customersStore.doFetchCustomerDocuments,
  doFetchMoreDocuments: dispatch.customersStore.doFetchMoreCustomerDocuments,
  setCustomerDocuments: dispatch.customersStore.setCustomerDocuments,
  doAddCustomerNotes: dispatch.customersStore.doAddCustomerNotes,
  doFetchBooking: dispatch.bookingsStore.doFetchSingleBooking,
  doEditCustomerNote: dispatch.customersStore.doEditCustomerNote,
  doAcceptEnquiry: dispatch.customersStore.doAcceptEnquiry,
  resetUserServiceAgreementBillingLineItems: dispatch.customersStore.resetUserServiceAgreementBillingLineItems,
  setSelectedCustomerUserId: (customerUserId: string) =>
    dispatch.customersStore.setSelectedCustomerUserId({ customerUserId }),
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doFetchWorkflowListOnProfile: dispatch.workflowStore.doFetchWorkflowListOnProfile,
  setIsCreatePackageModalOpen: dispatch.customersStore.setIsCreatePackageModalOpen,
});

export default connect(mapState, mapDispatch)(withLDConsumer()(CustomerDetailsPanel));
