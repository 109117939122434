import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FieldValueText, Text, Title } from 'common-components/typography';
import { Avatar, Col, Icon, Row, Skeleton } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormComponentProps } from 'antd/es/form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { TeamStatus } from 'utilities/enum-utils';
import moment from 'moment';
import TeamDetailsTabs from './tabs-panel/TeamDetailsTabs';
import PermissionUtils from 'utilities/permission-utils';
import { ageDisplay } from 'utilities/date-utils';

interface IWorkerUrlParams {
  workerId: string;
}

interface ITeamDetailsViewProps extends FormComponentProps, RouteComponentProps<IWorkerUrlParams> {
  doFetchSelectedWorker: typeof dispatch.teamStore.doFetchSelectedWorker;
  selectedWorkerItem: any;
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
  portalUser: typeof state.authStore.portalUser;
}

interface ITeamDetailsViewState {
  isLoading: boolean;
  activeKey: string;
}

class TeamDetailsViewClass extends Component<ITeamDetailsViewProps, ITeamDetailsViewState> {
  override state = {
    isLoading: false,
    activeKey: 'generalinfo',
  };

  override async componentDidMount() {
    const { match, doFetchSelectedWorker, doGetTimeAvailability, portalUser, history } = this.props;

    if (!PermissionUtils.validatePermission('ViewTeamListing', portalUser.permissions.permissionRoles)) {
      history.push('/access-denied');
    }

    const { params } = match;
    const selectedWorkerId = params.workerId;
    this.setState({ isLoading: true });
    await doFetchSelectedWorker({ supportWorkerId: selectedWorkerId });
    await doGetTimeAvailability({});
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <>
          <div className='item-container'>
            <div className='pb-medium'>
              <Text>Fetching team member details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} active avatar title paragraph />
        </>
      );
    } else {
      const { selectedWorkerItem } = this.props;
      let primaryAddress;
      let primaryContact;
      if (!_.isEmpty(selectedWorkerItem)) {
        primaryAddress = _.find(selectedWorkerItem.addresses, (address) => {
          return address.isPrimary === true;
        });
        primaryContact = _.find(selectedWorkerItem.contactNumbers, (contactNumber) => {
          return contactNumber.preferredContact === true;
        });
      } else {
        primaryAddress = '';
        primaryContact = '';
      }

      return (
        <div>
          {!_.isEmpty(selectedWorkerItem) ? (
            <div className='mb-x7-large' id='scroll'>
              <Row>
                <div className='mb-medium'>
                  <HyperlinkButton onClick={() => this.props.history.push('/team')}>
                    <Icon type='left' /> Back to listing
                  </HyperlinkButton>
                </div>
              </Row>
              <Title level={2}>Team member details</Title>

              <div className='mv-x-large  align-center'>
                <Row>
                  <Col span={3}>
                    <Avatar
                      icon='user'
                      shape='circle'
                      className='avatar-x3-large'
                      src={selectedWorkerItem.attachmentUrl}
                    />
                  </Col>
                  <Col span={20}>
                    <div className='text-align-left pr-medium'>
                      <Row>
                        <Col>
                          <Title level={2} color='blue' className='align-center flex'>
                            {`${selectedWorkerItem.firstName} ${selectedWorkerItem.lastName}`}
                            {/* Commented out until we have a logic behind beeing online */}
                            {/* <PrimaryButton size={'small'} className={'ml-small'} color={'forest-dark'}>
                                Online
                              </PrimaryButton> */}
                          </Title>
                          {selectedWorkerItem.status === TeamStatus.REMOVED ? (
                            <>
                              <div
                                style={{
                                  width: '100%',
                                  backgroundColor: 'rgba(255,235,235,0.5)',
                                  border: '1px solid #FF7373',
                                  borderRadius: 8,
                                  verticalAlign: 'top',
                                  height: '40px',
                                }}
                                className='p-small mt-small flex justify-start'
                              >
                                <div className='mt-x2-small'>
                                  <Icon
                                    type='close-circle'
                                    theme='filled'
                                    className='text-color-red-light text-size-x2-large'
                                  />
                                </div>
                                <div className='ml-small'>
                                  <div>
                                    <Text style={{ verticalAlign: 'top' }}>
                                      This team member was removed from the system on{' '}
                                      <b>{moment(selectedWorkerItem.removedOn).format('DD/MM/YYYY')}</b>
                                    </Text>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <Row type='flex'>
                                <Col span={1}>
                                  <Icon type='environment' />
                                </Col>
                                <Col span={8}>
                                  <FieldValueText
                                    text={
                                      primaryAddress?.locality && primaryAddress?.locality !== ''
                                        ? primaryAddress.locality
                                        : 'Not set'
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row type='flex'>
                                <Col span={1}>
                                  <Icon type='phone' />
                                </Col>
                                <Col span={8}>
                                  <FieldValueText
                                    text={
                                      primaryContact !== '' && primaryContact !== undefined
                                        ? parsePhoneNumberFromString(
                                            primaryContact.number,
                                            primaryContact.numberCountryCode !== '' &&
                                            primaryContact.numberCountryCode !== ''
                                              ? primaryContact.numberCountryCode
                                              : 'AU',
                                          ).format('INTERNATIONAL')
                                        : 'Not set'
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row type='flex'>
                                <Col span={1}>
                                  <Text> Age</Text>
                                </Col>
                                <Col span={8}>
                                  <Text>{ageDisplay(selectedWorkerItem.dateOfBirth)}</Text>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row className='mt-medium'>
                  <Col>
                    <TeamDetailsTabs />
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedWorkerItem: state.teamStore.selectedWorker,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSelectedWorker: dispatch.teamStore.doFetchSelectedWorker,
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
});

export const TeamDetailsView = connect(mapState, mapDispatch)(withRouter(TeamDetailsViewClass));
